import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import * as XLSX from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import {DatePipe} from "@angular/common";


@Component({
  selector: "app-toll-gate-view",
  templateUrl: "./toll-gate-view.component.html",
  styleUrls: ["./toll-gate-view.component.scss"]
})
export class TollGateViewComponent implements OnInit {

  searchText;
  getLocalPassDetails: TollGate[] = [];
  getPassDetails: TollGate[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  getDateValue: string[] = [];
  getVehicleType: string[] = [];
  cashFilter: string | null = null;
  typeFilter: string | null = null;
  DateFilter: string | null = null;
  isModalShow: boolean = false;
  cashDepositValues: TollGate[] = [];
  selectedCheckboxValues: number[] = [];
  updateTollPaymentDetails: TollGate[] = [];
  totalAmount: number = 0;
  seqValue: number[] = [];
  userType: string;

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService, private spinner: NgxSpinnerService,
    private dataServices: PrintDataService, private datePipe: DatePipe) {

  }

  gateForm = new UntypedFormGroup({
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  ngOnInit(): void {
    this.userType = localStorage.getItem("role");
    this.getAllPassDetails();
  }

  // Get all tollgate details to show in table
  getAllPassDetails() {
    this.spinner.show();
    this.apiServices.getAllTollGate()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.getLocalPassDetails = data;
        const vehicleType = new Set(this.getLocalPassDetails.map(item => item.tollgateVehicleDetails.vehType));
        this.getVehicleType = Array.from(vehicleType)
        console.log(this.getLocalPassDetails)
        const getDate = new Set(this.getLocalPassDetails.map(item => this.datePipe.transform(item.vehEntryDateTime * 1000, 'dd/MM/yyyy')))
        this.getDateValue = Array.from(getDate).sort((a, b) => {
          const dateA = new Date(a.split('/').reverse().join('/'));
          const dateB = new Date(b.split('/').reverse().join('/'));
          return dateB.getTime() - dateA.getTime(); // Sorts in ascending order
        });

        this.getPassDetails = this.getLocalPassDetails.sort((a, b) => {
          const idA = a.tollgateSeq;
          const idB = b.tollgateSeq;
          return idB - idA;
        });
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.error.message || "Something went wrong, Please try again later");
      });
  }

  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  }

// Filter function based on selection
  tollGateFilter(event: any, filterType: string) {
    const value = event.target.value;

    switch (filterType) {
      case "type":
        this.typeFilter = value !== "Select Vehicle Type" ? value : null;
        break;
      case "date":
        this.DateFilter = value !== "Select Date" ? value : null;
        break;
      case "cash":
        this.cashFilter = value !== "Select Cash Deposit" ? value : null;
        break;
      default:
        break;
    }
    this.applyFilters();
  }

  // Apply filter based data
  applyFilters() {
    const typeFiltered = this.typeFilter ? this.getLocalPassDetails.filter(item => item.tollgateVehicleDetails.vehType === this.typeFilter) : this.getLocalPassDetails;
    const numberFiltered = this.DateFilter ? typeFiltered.filter(item => this.datePipe.transform(item.vehEntryDateTime * 1000, 'dd/MM/yyyy') === this.DateFilter) : typeFiltered;
    const cashFiltered = this.cashFilter ? numberFiltered.filter(item => {
      if (this.cashFilter === "Not Deposit") {
        return item.cashDeposit === false;
      } else if (this.cashFilter === "Deposit") {
        return item.cashDeposit === true;
      }
      return true;
    }) : numberFiltered;
    this.getPassDetails = cashFiltered.sort((a, b) => {
      const idA = a.tollgateSeq;
      const idB = b.tollgateSeq;
      return idB - idA;
    });
  }

  // Excel generate for table data report
  generateVistorsXLSX() {
    const desiredColumns = ["tollgateVehicleDetails.vehType", "tollgateVehicleDetails.vehNumberPlate", "entryDate", "charges", "cashDeposit"];

    const header = desiredColumns.map(column => {
      const parts = column.split(".");
      return parts[parts.length - 1];
    });

    const headerStyle = {
      fill: {
        fgColor: { rgb: "black" }
      },
      font: {
        color: { rgb: "FFFFFF" },
        bold: true
      }
    };

    const filteredData = this.getPassDetails.map(item =>
      desiredColumns.reduce((obj, key) => {
        const keys = key.split(".");
        let value: any = item;
        keys.forEach(k => {
          if (value && typeof value === "object" && k in value) {
            value = value[k];
          } else {
            value = undefined;
          }
        });

        obj[key] = value;
        return obj;
      }, {})
    );

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);

    const workbook: XLSX.WorkBook = { Sheets: { "data": worksheet }, SheetNames: ["data"] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const data: Blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

    saveAs(data, "Toll Gate Details" + " Report.xlsx");
  }

  // Modal popup open
  modelPopup() {
    if (this.DateFilter != null) {
      this.isModalShow = true;
      this.getPassDetails = this.getLocalPassDetails.filter(item => item.entryDate == this.DateFilter);
      this.cashDepositValues = this.getPassDetails.filter(item => item.cashDeposit == false);
      const vanValues = this.cashDepositValues.map(item => item.charges);
      this.totalAmount = vanValues.reduce((total, charge) => total + charge, 0);
    } else {
      this.toaster.error("Please select date");
    }
  }

  // Modal popup close
  cencel() {
    this.isModalShow = false;
    window.location.reload();
  }

  // Update status not deposit to deposit & cirm No
  saveCash() {
    this.seqValue = this.cashDepositValues.map(item => item.tollgateSeq);
    this.selectedCheckboxValues = this.seqValue;
    if (this.selectedCheckboxValues.length != 0) {
      this.spinner.show();
      this.apiServices.updateCashStatus(this.selectedCheckboxValues)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide();
          this.updateTollPaymentDetails = data;
          this.saveAlert();
        }, (error: any) => {
          this.spinner.hide();
          this.toaster.error("Something went wrong, Please try again later");
        });
    } else {
      this.isModalShow = false;
    }
  }

  // Currency format condition
  formatCharges(charges: number): string {
    let formattedCharges = charges.toFixed(2);
    const [integerPart, decimalPart] = formattedCharges.split(".");
    formattedCharges = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedCharges += "." + decimalPart.padEnd(2, "0");
    return formattedCharges;
  }

  ngOnDestroy(): void {
    this.dataServices.tollGateDetails = this.updateTollPaymentDetails;
  }

  // receipt page navigate condition
  printDetails(aftc: any) {
    Swal.fire({
      title: "Do you want print?",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      denyButtonColor: "#3091d6",
      confirmButtonColor: "#30d66a",
      cancelButtonColor: "#d33",
      confirmButtonText: "A5 Print",
      denyButtonText: "Thermal Print",
      cancelButtonText: "No Print"
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem("reportQr", aftc.reportQr);
        sessionStorage.setItem("typeOfprint", "tollgateView");
        this.router.navigate(["toll-gate-print"]);
      } else if (result.isDenied) {
        sessionStorage.setItem("reportQr", aftc.reportQr);
        sessionStorage.setItem("typeOfprint", "tollgateView");
        this.router.navigate(["tollgate-receipt"]);
      }
    });
  }

  saveAlert() {
    Swal.fire({
      title: "Details Updated Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      this.router.navigate(["toll-gate-cash-print"]);
    });
  }

}
